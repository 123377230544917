<template>
  <div class="setUser">
    <div class="setUserBox">
      <!-- 换店铺头像--手机端 -->
      <section
        class="userImgBox"
        v-if="platform == 'android' && isApp"
        @click="setAvatar"
      >
        <p class="userImgBoxTitle">{{ $t.shopAvatar }}</p>
        <figure class="imgBox">
          <van-image
            class="userImg"
            :src="
              shopData.ShopHeadPhoto && imgUrlFilter(shopData.ShopHeadPhoto)
            "
          >
            <template v-slot:error>
              <img
                class="userImg"
                src="~assets/img/userManage/user_logo@2x.png"
              />
            </template>
          </van-image>
        </figure>
      </section>
      <!-- 换店铺头像--网页端/ios -->
      <section class="userImgBox" v-else>
        <van-uploader :after-read="afterRead">
          <p class="userImgBoxTitle">{{ $t.shopAvatar }}</p>
          <figure class="imgBox">
            <van-image
              class="userImg"
              :src="
                shopData.ShopHeadPhoto && imgUrlFilter(shopData.ShopHeadPhoto)
              "
            >
              <template v-slot:error>
                <img
                  class="userImg"
                  src="~assets/img/userManage/user_logo@2x.png"
                />
              </template>
            </van-image>
          </figure>
        </van-uploader>
      </section>

      <div class="inputBox">
        <label class="inputTitle">{{ $t.shopName }}</label>
        <input class="inputInfo" v-model="shopData.ShopName" type="text" />
      </div>

      <div class="textBox">
        <van-field
          v-model="shopData.ShopDescription"
          rows="3"
          autosize
          :label="$t.shopDesc"
          :maxlength="50"
          show-word-limit
          type="textarea"
        />
      </div>

      <div class="loginOutBox" @click="changeInfo">
        <div class="loginOut">{{ $t.confirm }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Image, Uploader, Field } from "vant";
import { isAPP, getPlatform } from "@/plugins/environment/type.js";

Vue.use(Image).use(Uploader).use(Field);
export default {
  name: "EditStore",
  components: {},
  data() {
    return {
      isApp: isAPP(),
      platform: getPlatform(),
      shopData: {},
    };
  },
  mounted() {
    this.getShopInfo();
  },
  created() {
    window.openAlbum = this.openAlbum;
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value);
    },
    getShopInfo() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {};
      this.$api.shop
        .loadShopInfo(param)
        .then((res) => {
          this.shopData = res.data;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    setAvatar() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.openAlbum, {
        max: 1,
        callback: "openAlbum",
      });
    },
    openAlbum(data) {
      var file = JSON.parse(data);
      let param = {
        ShopHeadPhoto: file.DefaultUrl,
      };
      this.editShopInfo(param);
    },
    editShopInfo(param) {
      this.$api.shop
        .EditShopInfo(param)
        .then((res) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
            titleMsg: res.message,
            type: "success",
          });
          this.getShopInfo();
        })
        .catch((err) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showNotify, {
            titleMsg: err.message,
            type: "danger",
          });
        });
    },
    changeInfo() {
      let param = {
        ShopName: this.shopData.ShopName,
        ShopDescription: this.shopData.ShopDescription,
      };
      this.editShopInfo(param);
    },
    afterRead(file) {
      var formData = new FormData();
      formData.append("file", file.file);
      this.$commonMethod.showLoading();
      this.$api.system
        .uploadImgFile(formData)
        .then((res) => {
          let param = {
            ShopHeadPhoto: res.data.DefaultUrl,
          };
          this.editShopInfo(param);
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin publicUser {
  border-radius: 8px;
  background-color: #ffffff;
  padding: 15px 15px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  @include publicFlex;
}
@mixin fontCommon {
  font-size: 14px;
  color: #777777;
  font-family: Helvetica;
  font-weight: bold;
}
.setUser {
  width: 100%;
  .setUserBox {
    width: 95%;
    margin: 0 auto;
    .userImgBox {
      margin-top: 10px;
      min-height: 70px;
      .van-uploader {
        width: 100%;
        .userImgBoxTitle {
          line-height: 50px;
          display: inline-block;
        }
        .imgBox {
          float: right;
        }
        ::v-deep .van-uploader__input-wrapper {
          width: 100%;
        }
      }
      @include publicUser;
      .userImgBoxTitle {
        @include divPublic;
        font-size: 14px;
        color: #000000;
      }
      .imgBox {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        border: 1px solid #c4e6d5;
        @include divPublic;
        ::v-deep .van-image__error {
          border-radius: 100%;
          position: block;
        }
        ::v-deep .van-image__img {
          border-radius: 100%;
          object-fit: cover;
        }
        .userImg {
          width: 50px;
          height: 50px;
          border-radius: 100%;
          object-fit: cover;
        }
      }
      .backBox {
        width: 15px;
        @include divPublic;
        .backBoxImg {
          width: 100%;
        }
      }
      .userName {
        font-size: 14px;
        color: #000000;
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }
  .inputBox {
    margin-top: 10px;
    min-height: 70px;
    @include publicUser;
    .inputTitle {
      @include divPublic;
      font-size: 14px;
      color: #000000;
    }
    .inputInfo {
      background: none;
      outline: none;
      border: none;
      flex: 1;
      text-align: right;
      @include fontCommon;
      &::placeholder {
        font-size: 12px;
        font-family: Helvetica;
        font-weight: 400;
        color: #cacaca;
        text-align: right;
      }
    }
  }
  .textBox {
    margin-top: 10px;
    min-height: 70px;
    border-radius: 8px;
    background-color: #ffffff;
    padding: 15px 15px;
    .van-field {
      padding: 0;
      ::v-deep .van-field__label {
        color: #000000;
      }
      ::v-deep textarea {
        color: #777777 !important;
        font-family: Helvetica;
        font-weight: bold;
      }
    }
  }
  .loginOutBox {
    width: $publicWidth;
    margin: 8% auto;
    .loginOut {
      position: relative;
      width: 100%;
      margin: 0 auto;
      border-radius: 3rem;
      padding: 0.8rem 0;
      text-align: center;
      background: linear-gradient(left, #71d283, #01aaa3);
      color: #ffffff;
      font-size: 1rem;
      line-height: 1;
    }
  }
}
</style>
